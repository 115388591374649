
import {defineComponent, ref} from 'vue'
import ActivityService from "@/core/services/ActivityService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import Translate from "@/views/translate/Translate.vue";

export default defineComponent({
  name: "Transcribe",
  components: {Translate, EntityLoading},
  props: {
    id: {}
  },
  setup(props) {
    const data = ref<any>([]);
    const loading = ref(true);
    const load = (id) => {
      loading.value = true
      ActivityService.getTranscribe(id).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    load(props.id);
    return {
      data,
      loading,
    }
  }
})
