
import {computed, defineComponent, ref, watch} from 'vue'
import store from "@/store";
import TranslateService from "@/core/services/TranslateService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";

export default defineComponent({
  name: "Translate",
  components: {EntityLoading},
  props: {
    body: {},
    lang: {},
  },
  setup(props) {
    const user = computed(() => store.getters.currentUser)
    const translated = ref('');
    const typeShow = ref('original')
    const loading = ref(false);
    watch(() => props.body, () => {
      translated.value = ''
    })

    watch(() => props.lang, () => {
      translated.value = ''
    })
    return {
      translated,
      user,
      typeShow,
      loading
    }
  },
  methods: {
    translate() {
      this.typeShow = 'translate'
      if (!this.translated) {
        this.loading = true;
        TranslateService.create<any>({body: this.body, sourceLang: this.lang, targetLang: this.user.lang}).then(res => {
          this.translated = res.translate;
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    original() {
      this.typeShow = 'original'
    }
  }
})
